import moment from "moment";

export const checkExpiration = (date) => {
  const currentDate = new Date();
  const expiryDate = new Date(date);
  if (currentDate > expiryDate) {
    return true;
  }
  return false;
};

export const CurrencyConverter = (string) => {
  if (string === "USD") {
    return "$";
  } else if (string === "AUD") {
    return "$";
  } else if (string === "INR") {
    return "₹";
  } else {
    return "$";
  }
};

export const InrToUsdConverter = (string, amount) => {
  if (string === "USD") {
    return AmountFormater(amount / 10);
  } else if (string === "AUD") {
    return AmountFormater(amount / 10);
  } else if (string === "INR") {
    return AmountFormater(amount * 10);
  } else {
    return AmountFormater(amount / 10);
  }
};

export const AmountFormater = (number) => {
  if (typeof number === "number" && !isNaN(number)) {
    return number.toFixed(2);
  } else {
    return false;
  }
};

export const AllAlphbet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export const detectBrowser = () => {
  let data = {
    browser: "Internet Explorer",
    mobile: false,
    mobileOs: "unknown",
  };
  let browserNames = "";
  let mobileOs = "unknown";

  if (navigator.userAgentData) {
    const userNavigatorAgentData = navigator.userAgentData;
    userNavigatorAgentData?.brands?.forEach((brandObj) => {
      if (brandObj?.brand?.indexOf("Google Chrome") > -1) {
        browserNames = "Chrome";
      } else if (brandObj?.brand?.indexOf("Microsoft Edge") > -1) {
        browserNames = "Edge";
      } else if (brandObj?.brand?.indexOf("Mozilla Firefox") > -1) {
        browserNames = "Firefox";
      } else if (brandObj?.brand?.indexOf("Safari") > -1) {
        browserNames = "Safari";
      } else if (brandObj?.brand?.indexOf("Samsung Internet") > -1) {
        browserNames = "Samsung";
      } else if (brandObj?.brand?.indexOf("Baidu Browser") > -1) {
        browserNames = "Baidu";
      } else if (brandObj?.brand?.indexOf("Tor Browser") > -1) {
        browserNames = "Tor";
      }
    });
    data = {
      browser: browserNames ? browserNames : "Unknown",
      mobile: userNavigatorAgentData?.mobile,
    };
  } else if (typeof window !== "undefined") {
    const userAgent = window.navigator.userAgent;
    if (userAgent.indexOf("Edg") > -1) {
      browserNames = "Edge";
    } else if (userAgent.indexOf("Chrome") > -1) {
      browserNames = "Chrome";
    } else if (userAgent.indexOf("Firefox") > -1) {
      browserNames = "Firefox";
    } else if (userAgent.indexOf("Safari") > -1) {
      browserNames = "Safari";
    } else if (
      userAgent.indexOf("MSIE") > -1 ||
      userAgent.indexOf("Trident") > -1
    ) {
      browserNames = "Internet Explorer";
    }
    // find Mobile OS
    if (/windows phone/i.test(userAgent)) {
      mobileOs = "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
      mobileOs = "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      mobileOs = "iOS";
    }

    data = {
      browser: browserNames ? browserNames : "",
      mobile: false,
      mobileOs: mobileOs,
    };
  }

  return data;
};

export const deviceDetection = () => {
  const width = window.innerWidth;
  const height = window.innerHeight;

  if (width <= 600 && height >= width) {
    return "Mobile"; // Mobile in portrait
  } else if (width <= 800 && height < width) {
    return "Mobile"; // Mobile in landscape
  } else if (width <= 900) {
    return "Tablet";
  } else {
    return "Desktop";
  }
};

export const agentAllowUrls = (location) => {
  const adminUrl = "/admin/";
  const allowUrls = [
    "dashboard",
    "profile",
    "client/sub-users",
    "exercise",
    "edit-exercise",
    "logout",
  ];
  for (let i = 0; i < allowUrls.length; i++) {
    if (location.startsWith(adminUrl + allowUrls[i])) {
      return true;
    }
  }
  return false;
};

export const subscriptionRemainingDays = (futureDate) => {
  let diffInDays = 0;
  if (futureDate) {
    const currentDate = moment();
    diffInDays = moment(futureDate).diff(currentDate, "days");
  }

  return diffInDays;
};
