import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { Helmet } from "react-helmet-async";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { AdminFooter } from "../../Layout";
import {
  AddWordStackService,
  GetSearchWordStackService,
} from "../../../Services/Api/wordStack";
import { GetLanguageList } from "../../../Services/Api/language";
import { GetWordCategoryListService } from "../../../Services/Api/wordStackCategory";

const AddWordStack = () => {
  const [searchWord, setSearchWord] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [categories, setCategories] = useState([]);

  const signInSchema = Yup.object().shape({
    language_id: Yup.object().required("Word Language is required"),
    // category_id: Yup.object().required("Word Category is required"),
    first_language: Yup.string().required("Original Word is required"),
    second_language: Yup.string().required("Translate Word is required"),
  });

  const getLanguageList = async () => {
    await GetLanguageList()
      .then((res) => {
        if (res?.data?.data?.length) {
          const languageData = res?.data?.data?.map((language) => {
            return {
              label: language?.name,
              value: language?._id,
            };
          });
          setLanguages(languageData);
        } else {
          setLanguages([]);
        }
      })
      .catch((err) => {
        setLanguages([]);
      });
  };

  const getCategoriesList = async () => {
    await GetWordCategoryListService()
      .then((res) => {
        if (res?.data?.data?.length) {
          const categoriesData = res?.data?.data?.map((category) => {
            return {
              label: category?.name,
              value: category?._id,
            };
          });
          setCategories(categoriesData);
        } else {
          setCategories([]);
        }
      })
      .catch((err) => {
        setCategories([]);
      });
  };

  const getSearchWordStackService = async (search) => {
    await GetSearchWordStackService(search)
      .then((res) => {
        if (res?.data?.data?.length) {
          setSearchWord(res?.data?.data);
        } else {
          setSearchWord([]);
        }
      })
      .catch((err) => {
        setSearchWord([]);
      });
  };

  const initialValues = {
    language_id: "",
    category_id: "",
    first_language: "",
    second_language: "",
  };

  useEffect(() => {
    getLanguageList();
    getCategoriesList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Add Word Pulse | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Add Word Pulse</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/word-stack">Word Pulse</Link>
                  </li>
                  <li className="breadcrumb-item active">Add Word</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/word-stack" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Basic Info</h4>
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={signInSchema}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting }) => {
                      const postData = {
                        type: 1,
                        first_language: values.first_language,
                        second_language: values.second_language,
                        language_id: values.language_id.value,
                        category_id: values.category_id.value,
                      };
                      await AddWordStackService(postData)
                        .then((res) => {
                          SuccessToast(
                            res?.data?.message ||
                              "WordStack created successfully."
                          );
                          setSubmitting(false);
                          window.location.reload(true);
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {(formik) => {
                      const { errors, touched, isSubmitting, setFieldValue } =
                        formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Language{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Select
                                  options={languages}
                                  onChange={(newVal) => {
                                    setFieldValue("language_id", newVal);
                                  }}
                                  name="language_id"
                                  placeholder="Select Language"
                                />
                                <ErrorMessage
                                  name="language_id"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Categories</label>
                                <Select
                                  options={categories}
                                  onChange={(newVal) => {
                                    setFieldValue("category_id", newVal);
                                  }}
                                  name="category_id"
                                  placeholder="Select Category"
                                />
                                <ErrorMessage
                                  name="category_id"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Original{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.first_language &&
                                    touched.first_language
                                      ? "input-error"
                                      : null
                                  }`}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "first_language",
                                      e.target.value
                                    );
                                    if (e.target.value.length >= 3) {
                                      getSearchWordStackService(e.target.value);
                                    }
                                  }}
                                  name="first_language"
                                  placeholder="Enter Original Language"
                                />
                                <ErrorMessage
                                  name="first_language"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Translate{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.second_language &&
                                    touched.second_language
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="second_language"
                                  placeholder="Enter Translate Language"
                                />
                                <ErrorMessage
                                  name="second_language"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Add Word
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
              {searchWord?.length > 0 && (
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Suggestion</h4>
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          className="table-responsive custom-pagination"
                          style={{ height: "200px", overflowY: "auto" }}
                        >
                          <table className="table table-center datatable">
                            <thead className="thead-light">
                              <tr>
                                <th>Language</th>
                                <th>Original</th>
                                <th>Translate</th>
                              </tr>
                            </thead>
                            <tbody>
                              {searchWord?.map((word, index) => (
                                <tr key={index}>
                                  <td>{word?.language?.name}</td>
                                  <td>{word.first_language}</td>
                                  <td>{word.second_language}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <AdminFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddWordStack;
