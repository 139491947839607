import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Select from "react-select";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BrowserVoices = (props) => {
  const { selectVoicePopup, setSelectVoicePopup, setSelectVoice } =
    props;
  const [voices, setVoices] = useState([]);
  const [selectBoxVoice, setSelectBoxVoice] = useState("");

  const handleClose = () => {
    setSelectVoicePopup(false);
  };

  const handleClick = () => {
    if (selectBoxVoice) {
        // Find a valid voice for the language
        const selectedVoice111 = voices.find(voice => voice.lang === "gu-IN") 
        || voices.find(voice => voice.lang === "hi-IN") 
        || null;
        console.log('selectedVoice11 = ', selectedVoice111);

      setSelectVoice(selectBoxVoice);
      setSelectVoicePopup(false);
    }
  };

  useEffect(() => {
    const getVoices = () => {
      const availableVoices = window.speechSynthesis.getVoices();
      let voicesArr = availableVoices?.map((value) => {
        return {
          default: value.default,
          localService: value.localService,
          voiceURI: value.voiceURI,
          name: value.name,
          lang: value.lang,
          label: value.name,
          value: value.lang,
        };
      });
      setVoices(voicesArr);
    };
    window.speechSynthesis.onvoiceschanged = getVoices;
    getVoices();
  }, []);

  return (
    <Modal
      open={selectVoicePopup}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Select
          options={voices}
          onChange={(newVal) => {
            setSelectBoxVoice(newVal);
          }}
          name="browser_voice"
          placeholder="Select Voice"
        />
        <button className="btn btn-primary btnstl" onClick={handleClick}>
          Save Voice
        </button>
        <button className="btn btn-danger btnstl" onClick={handleClose}>
          Close
        </button>
      </Box>
    </Modal>
  );
};

export default BrowserVoices;
