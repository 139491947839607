import { legacy_createStore as createStore } from "redux";
import { combineReducers, applyMiddleware, compose } from "redux";
import { thunk } from "redux-thunk";
import reducers from "../Api/reducers";

const isBrowser = typeof window === "object";

// create the master reducer
const rootReducer = combineReducers({
  reducers,
});

const initialState = isBrowser && (window.__INITIAL_STATE__ || {});

const reduxMiddleware = compose(
  applyMiddleware(thunk),
  isBrowser && typeof window.devToolsExtension !== "undefined"
    ? window.devToolsExtension()
    : (f) => f
);

const store = () => createStore(rootReducer, initialState, reduxMiddleware);

export default store;
