import { Route, Routes } from "react-router-dom";
import { AdminLayout } from "../Components/Layout";
import PrivateRoute from "./PrivateRoute";
import { useEffect, useState } from "react";
import { User, AddUser } from "../Components/Admin/UserManagement";
import ClientUser from "../Components/Admin/UserManagement/Client/ClientUser";
import { Language, AddLanguage } from "../Components/Admin/language";
import {
  Category,
  AddCategory,
  EditCategory,
} from "../Components/Admin/category";
import { Exercise, ExerciseView } from "../Components/Admin/Exercise";
import { Product, AddProduct, EditProduct } from "../Components/Admin/product";
import Dashboard from "../Components/Admin/Dashboard";
import Profile from "../Components/Admin/Profile";
import { AddFaq, Faq } from "../Components/Admin/Faq";
import {
  WordStack,
  AddWordStack,
  EditWordStack,
} from "../Components/Admin/WordStack";
import {
  WordStackCategory,
  AddWordStackCategory,
  EditWordStackCategory,
} from "../Components/Admin/WordStackCategory";
import { Support, ReplySupport } from "../Components/Admin/Support";
import { Feedback } from "../Components/Admin/Feedback";
import AdminContactUs from "../Components/Admin/Contactus/AdminContactUs";
import { Credits } from "../Components/Admin/Credits";

const Logout = () => {
  useEffect(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("adminData");
    localStorage.removeItem("adminPermission");
    window.location.assign("/admin/auth/login");
  }, []);
};

const AdminRoutes = () => {
  const [adminLoading, setAdminLoading] = useState(true);

  useEffect(() => {
    if (localStorage?.getItem("accessToken")) {
      setAdminLoading(false);
    } else {
      setAdminLoading(true);
      window.location.assign("/admin/auth/login");
    }
  }, []);

  return !adminLoading ? (
    <>
      <AdminLayout />
      <Routes>
        <Route
          exact
          path="/"
          element={<PrivateRoute Component={Dashboard} />}
        />
        <Route
          exact
          path="/dashboard"
          element={<PrivateRoute Component={Dashboard} />}
        />
        <Route
          exact
          path="/users"
          element={<PrivateRoute Component={User} />}
        />
        <Route
          exact
          path="/client/sub-users"
          element={<PrivateRoute Component={ClientUser} />}
        />
        <Route
          exact
          path="/add-user"
          element={<PrivateRoute Component={AddUser} />}
        />
        <Route
          exact
          path="/language"
          element={<PrivateRoute Component={Language} />}
        />
        <Route
          exact
          path="/add-language"
          element={<PrivateRoute Component={AddLanguage} />}
        />
        <Route
          exact
          path="/category"
          element={<PrivateRoute Component={Category} />}
        />
        <Route
          exact
          path="/add-category"
          element={<PrivateRoute Component={AddCategory} />}
        />
        <Route
          exact
          path="/edit-category"
          element={<PrivateRoute Component={EditCategory} />}
        />
        <Route
          exact
          path="/product"
          element={<PrivateRoute Component={Product} />}
        />
        <Route
          exact
          path="/add-product"
          element={<PrivateRoute Component={AddProduct} />}
        />
        <Route
          exact
          path="/edit-product"
          element={<PrivateRoute Component={EditProduct} />}
        />
        <Route
          exact
          path="/exercise"
          element={<PrivateRoute Component={Exercise} />}
        />
        <Route
          exact
          path="/edit-exercise"
          element={<PrivateRoute Component={ExerciseView} />}
        />
        <Route
          exact
          path="/profile"
          element={<PrivateRoute Component={Profile} />}
        />
        <Route exact path="/faq" element={<PrivateRoute Component={Faq} />} />
        <Route
          exact
          path="/add-faq"
          element={<PrivateRoute Component={AddFaq} />}
        />
        <Route
          exact
          path="/word-category"
          element={<PrivateRoute Component={WordStackCategory} />}
        />
        <Route
          exact
          path="/add-word-category"
          element={<PrivateRoute Component={AddWordStackCategory} />}
        />
        <Route
          exact
          path="/edit-word-category"
          element={<PrivateRoute Component={EditWordStackCategory} />}
        />
        <Route
          exact
          path="/word-stack"
          element={<PrivateRoute Component={WordStack} />}
        />
        <Route
          exact
          path="/add-word-stack"
          element={<PrivateRoute Component={AddWordStack} />}
        />
        <Route
          exact
          path="/edit-word-stack"
          element={<PrivateRoute Component={EditWordStack} />}
        />
        <Route
          exact
          path="/support"
          element={<PrivateRoute Component={Support} />}
        />
        <Route
          exact
          path="/reply-support"
          element={<PrivateRoute Component={ReplySupport} />}
        />
        <Route
          exact
          path="/feedback"
          element={<PrivateRoute Component={Feedback} />}
        />
        <Route
          exact
          path="/contact-us"
          element={<PrivateRoute Component={AdminContactUs} />}
        />
        <Route
          exact
          path="/credit"
          element={<PrivateRoute Component={Credits} />}
        />

        <Route exact path="/logout" element={<Logout />} />
      </Routes>
    </>
  ) : (
    <></>
  );
};
export default AdminRoutes;
