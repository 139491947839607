import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Pagination } from "../../../Utils";
import { GetContactusService } from "../../../Services/Api/comman";
import { AdminFooter } from "../../Layout";

const AdminContactUs = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [contactusListPagination, setContactusListPagination] = useState([]);

  const GetContactusList = async (limit = "", page = "") => {
    setIsLoading(true);
    await GetContactusService({ limit: limit, page: page })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.data?.length) {
          setContactusListPagination(res?.data?.data?.data);
          setTotalRecords(res?.data?.data?.paginate?.total);
        } else {
          setContactusListPagination([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setContactusListPagination([]);
        setTotalRecords(0);
        setIsLoading(false);
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    GetContactusList(limit, page);
  }, [limit, page]);

  return (
    <>
      <Helmet>
        <title>Contactus | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Contact Us</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-striped">
                      <thead className="thead-light">
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Subject</th>
                          <th>Message</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {contactusListPagination.map((contact, index) => (
                              <tr key={index}>
                                <td>{contact?.name || ""}</td>
                                <td>{contact?.email || ""}</td>
                                <td>{contact?.subject || ""}</td>
                                <td>{contact?.message || ""}</td>
                              </tr>
                            ))}
                            {contactusListPagination.length === 0 && (
                              <tr>
                                <td colSpan={4}>No Records Found.</td>
                              </tr>
                            )}
                          </>
                        )}
                        {isLoading && (
                          <tr>
                            <td align="center" colSpan={5}>
                              Loading...
                            </td>
                          </tr>
                        )}
                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={4}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <AdminFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminContactUs;
