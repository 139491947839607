import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { Helmet } from "react-helmet-async";
import {
  GetWordStackByIdService,
  EditWordStackService,
} from "../../../Services/Api/wordStack";
import { GetLanguageList } from "../../../Services/Api/language";
import { GetWordCategoryListService } from "../../../Services/Api/wordStackCategory";
import { AdminFooter } from "../../Layout";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";

const EditWordStack = () => {
  const stateData = useLocation();
  const { id } = stateData?.state || "";
  const navigate = useNavigate();

  const [wordStack, setWordStack] = useState();
  const [languages, setLanguages] = useState([]);
  const [categories, setCategories] = useState([]);

  const basicInfoSchema = Yup.object().shape({
    language_id: Yup.object().required("Language is required"),
    // category_id: Yup.object().required("Category is required"),
    first_language: Yup.string().required("Original Word is required"),
    second_language: Yup.string().required("Translate Word is required"),
  });

  const basicInitialValues = {
    first_language: wordStack?.first_language || "",
    second_language: wordStack?.second_language || "",
    language_id:
      languages.find((option) => wordStack?.language?._id === option.value) ||
      {},
    category_id:
      categories.find((option) => wordStack?.category?._id === option.value) ||
      {},
  };

  const getLanguageList = async () => {
    await GetLanguageList()
      .then((res) => {
        if (res?.data?.data?.length) {
          const languageData = res?.data?.data?.map((language) => {
            return {
              label: language?.name,
              value: language?._id,
            };
          });
          setLanguages(languageData);
        } else {
          setLanguages([]);
        }
      })
      .catch((err) => {
        setLanguages([]);
      });
  };

  const getCategoriesList = async () => {
    await GetWordCategoryListService()
      .then((res) => {
        if (res?.data?.data?.length) {
          const categoriesData = res?.data?.data?.map((category) => {
            return {
              label: category?.name,
              value: category?._id,
            };
          });
          setCategories(categoriesData);
        } else {
          setCategories([]);
        }
      })
      .catch((err) => {
        setCategories([]);
      });
  };

  const getWordStackById = async (id) => {
    await GetWordStackByIdService(id)
      .then((res) => {
        setWordStack(res?.data?.data);
      })
      .catch((err) => {
        if (typeof err.response.data.message !== "undefined") {
          ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      });
  };

  useEffect(() => {
    getLanguageList();
    getCategoriesList();
    getWordStackById(id);
  }, [id]);

  return (
    <>
      <Helmet>
        <title>Edit Word Pulse | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Edit Word Pulse</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/word-stack">Word Pulses</Link>
                  </li>
                  <li className="breadcrumb-item active">Edit Word Pulse</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/word-stack" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Basic Info</h4>
                  <Formik
                    enableReinitialize
                    initialValues={basicInitialValues}
                    validationSchema={basicInfoSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                      const postObj = {
                        type: 1,
                        first_language: values?.first_language,
                        second_language: values?.second_language,
                        language_id: values.language_id?.value,
                        category_id: values.category_id?.value,
                      };
                      await EditWordStackService(id, postObj)
                        .then((res) => {
                          SuccessToast(
                            res?.data?.message || "Word Pulse updated."
                          );
                          setSubmitting(false);
                          navigate("/admin/word-stack");
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {({
                      errors,
                      touched,
                      handleChange,
                      isSubmitting,
                      setFieldValue,
                      values,
                    }) => {
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Language{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Select
                                  options={languages}
                                  className={`z-index-999`}
                                  onChange={(e) => {
                                    setFieldValue("language_id", e);
                                  }}
                                  name="language_id"
                                  value={values.language_id}
                                  placeholder="Select language"
                                />
                                <ErrorMessage
                                  name="language_id"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Category</label>
                                <Select
                                  options={categories}
                                  className={`z-index-999`}
                                  onChange={(e) => {
                                    setFieldValue("category_id", e);
                                  }}
                                  name="category_id"
                                  value={values.category_id}
                                  placeholder="Select category"
                                />
                                <ErrorMessage
                                  name="category_id"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Original{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.first_language &&
                                    touched.first_language
                                      ? "input-error"
                                      : null
                                  }`}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "first_language",
                                      e.target.value
                                    );
                                  }}
                                  value={values?.first_language}
                                  name="first_language"
                                  placeholder="Enter Original Language"
                                />
                                <ErrorMessage
                                  name="first_language"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Translate{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.second_language &&
                                    touched.second_language
                                      ? "input-error"
                                      : null
                                  }`}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "second_language",
                                      e.target.value
                                    );
                                  }}
                                  value={values?.second_language}
                                  name="second_language"
                                  placeholder="Enter Translate Language"
                                />
                                <ErrorMessage
                                  name="second_language"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Edit Category
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <AdminFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditWordStack;
